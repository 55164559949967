// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: rem(20px) rem(30px);
	text-decoration: none;
	color: $navColor;
	font-size: rem(17px);
	font-weight: 300;
	letter-spacing: rem(2px);
	border-bottom: rem(1px) solid $border;

	@include breakpoint($break) {
		font-size: rem(16px);
		padding: rem(55px) rem(10px);
		letter-spacing: 0;
		border-bottom: 0;
	}

	@include breakpoint(huge) {
		letter-spacing: rem(1px);
		padding: rem(55px) rem($baseGap);
	}

	@include breakpoint(full) {
		letter-spacing: rem(2px);
	}

	@include breakpoint(cut) {
		font-size: rem(17px);
		padding: rem(55px) rem(22px);
	}
}
%buttonHover {
	// Link Hover
	color: $primary;
	border-bottom: rem(1px) solid $primary;

	@include breakpoint($break) {
		border-bottom: 0;
	}
}
%buttonActive {
	// Link Active
	color: $primary;
	border-bottom: rem(1px) solid $primary;
	font-weight: 800;

	@include breakpoint($break) {
		border-bottom: 0;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 70px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	.col {
		padding-left: 0;
		padding-right: 0;
	}


	nav {
		margin-bottom: rem(100px);

		.branding {
			display: none;
		}

		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: rem(20px) rem(30px);
								background-color: darken($navBg, 6%);
								font-weight: 800;
								text-transform: uppercase;
								letter-spacing: rem(3px);
							}
						}
					}
					@include navBack {
					}

					&.produkte {
						&.hasSub {
							.sub {
								li {
									&:nth-child(2n+3) {
										a {
											border-bottom: 0;
											padding-bottom: 0;
										}
									}

									&.produktbeschreibungen {
										a {
											font-style: italic;
											padding-top: 0;
											font-size: rem(15px);
										}
									}
								}
							}
						}
					}

					span {
						transition: 300ms all ease-in-out;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background: rgba($dark, 0.5);
		transition: 300ms all ease-in-out;

		.col {
			padding-left: rem($baseGap);
			padding-right: rem($baseGap);
		}

		nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0;

			.branding {
				display: inline-block;
				max-width: 171px;
				width: 100%;
				height: auto;
				position: relative;
				z-index: 1;
				margin-right: rem(10px);

				@include breakpoint(huge) {
					margin-right: rem($baseGap);
				}

				@include breakpoint(cut) {
					margin-right: rem(22px);
				}
			}

			ul {
				&.navi {
					li {
						&.startseite, &.gesetzlicheAuflagen, &.kontakt, &.anfahrt, &.impressum, &.datenschutzerklaerung, &.stellenangebote {
							display: none;
						}
						&.produkte {
							&.hasSub {
								.sub {
									display: flex;
									flex-flow: row wrap;
									width: rem(555px);
									padding-bottom: 0;
									left: 50%;
									transform: translateX(-50%);

									@include breakpoint(full) {
										width: rem(610px);
									}

									li {
										&.produkte {
											width: 100%;
										}

										&:nth-child(n+2):nth-child(odd) {
											width: 50%;
										}

										a {
											border-bottom: rem(1px) solid $border;
										}

										&:nth-child(2n+3) {
											a {
												border-bottom: rem(1px) solid $border;
												padding-bottom: rem(15px);
											}
										}

										&.produktbeschreibungen {
											a {
												padding-top: rem(15px);
											}
										}
										// &:nth-child(2n) {
										// 	a {
										// 		border-bottom: rem(1px) solid $border;
										// 	}
										// }

										// &:last-child {
										// 	a {
										// 		border-bottom: 0;
										// 	}
										// }
									}
								}
							}
						}
						&.hasSub {
							.sub {
								padding-bottom: rem(15px);
								box-shadow: 6px -2px 15px rgba(0,0,0, .35);

								li {
									a {
										padding: rem(15px) rem(30px);										
									}

									&.navBack {
										position: absolute;
										right: 100%;
										background: $primary;
										padding: rem(15px);

										&:before {
											color: $light;
											transition: 300ms all ease-in-out;
										}

										&:hover {
											&:before {
												color: $border;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.navFixed {
			background: rgba($dark, 0.9);
			box-shadow: 6px -2px 15px rgba(0,0,0, .35);

			nav {
				.branding {
					max-width: rem(130px);
				}

				ul {
					&.navi {
						li {
							a, span {
								padding-top: rem(35px);
								padding-bottom: rem(35px);
							}
						}
					}
				}	
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		max-width: rem(100px);
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	justify-content: center;
	background: $dark;
	flex-flow: column nowrap;
	// padding-bottom: 100px;

	@include breakpoint(small) {
		flex-flow: row wrap;
	}

	@include breakpoint(medium) {
		padding-bottom: 0px;
	}
	
	li {
		@include breakpoint(small) {
			width: 50%;
		}


		@include breakpoint(large) {
			width: 33.3333%;
		}

		@include breakpoint(huge) {
			width: auto;
		}

		a {
			display: block;
			text-align: center;
			width: 100%;
			padding: rem(20px) rem(30px);
			border-bottom: rem(1px) solid $border;
			letter-spacing: rem(1px);
			font-size: rem(17px);
			color: $light;
			font-weight: 300;

			@include breakpoint(giant) {
				padding: rem(30px);
			}

			@include breakpoint(huge) {
				border-bottom: 0;
				padding: rem(50px) rem(35px);
			}

			&:hover, &.active {
				border-bottom: rem(1px) solid $primary;
				color: $primary;
				text-shadow: 2px 2px 20px rgba($primary,0.6);

				@include breakpoint(huge) {
					border-bottom: 0;
				}
			}

			&.active {
				font-weight: 800;
			}
		}
	}
}