// Use this file for your custom mixins/functions

@mixin space($space) 
{
	padding-top: #{rem($baseLineHeight * 2)};
	padding-bottom: #{rem($baseLineHeight * 1)};
	
	@if ($space < ($baseLineHeight * 3))
	{
		@include breakpoint(small) 
		{
			padding-top: rem($space);
			padding-bottom: rem($space - $baseLineHeight);
		}
	}
	@else 
	{
		@include breakpoint(small)
		{
			padding-top: #{rem($baseLineHeight * 3)};
			padding-bottom: #{rem($baseLineHeight * 2)};
		}

		@if ($space < ($baseLineHeight * 5))
		{
			@include breakpoint(giant) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $baseLineHeight);
			}
		}
		@else 
		{
			@include breakpoint(giant)
			{
				padding-top: #{rem($baseLineHeight * 5)};
				padding-bottom: #{rem($baseLineHeight * 4)};
			}
			@include breakpoint(huge) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $baseLineHeight);
			}		
		}
	}
}

.space {
	@include space(140px);
}

body:not(.cmsBackend) {
    @include breakpoint(large) {
      .animate-bottom-top{
        transform:translateY(100px);
        opacity:0;
        transition: all 1400ms linear;

        &.isOnScreen{
          transform:translateY(0px);
          opacity:1;
        }
      }

      .animate-top-bottom{
        transform:translateY(-100px);
        opacity:0;
        transition: all 900ms linear;

        &.isOnScreen{
          transform:translateY(0px);
          opacity:1;
        }
      }

      .animateLeftRight{
        transform:translateX(-100px);
        opacity: 0;
        transition: all 900ms linear;

        &.isOnScreen{
          opacity: 1;
          transform:translateX(0px);
        }
      }

      .animateRightLeft{
        transform:translateX(100px);
        opacity: 0;
        transition: all 900ms linear;

        &.isOnScreen{
          opacity: 1;
          transform:translateX(0px);
        }
      }
    } 
}