
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top: rem(70px);

	@include breakpoint(giant) {
		margin-top: 0;
	}

	.claimContainer {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;

		@include breakpoint(cut) {
			max-width: rem(1620px);
			left: 50%;
			transform: translate(-50%);
		}
		
		.claim {
			padding-top: rem(70px);

			@include breakpoint(large) {
				padding-top: rem(140px);
			}

			@include breakpoint(giant) {
				padding-top: rem(250px);
			}

			@include breakpoint(full) {
				padding-top: rem(350px);
			}

			blockquote {
				font-size: rem(19px);
				line-height: rem(29px);
				color: $light;
				text-transform: uppercase;
				font-weight: 800;
				margin-bottom: rem(30px);
				letter-spacing: rem(2px);
				text-shadow: 2px 2px 20px rgba($dark,0.6);

				@include breakpoint(tiny) {
					font-size: rem(30px);
					line-height: rem(40px);
				}

				@include breakpoint(small) {
					font-size: rem(40px);
					line-height: rem(50px);
					margin-bottom: rem(50px);
					letter-spacing: rem(3px);
				}

				@include breakpoint(medium) {
					max-width: rem(800px);
				}

				@include breakpoint(large) {
					font-size: rem(60px);
					line-height: rem(70px);
					letter-spacing: rem(4px);
					max-width: rem(1000px);
				}

				@include breakpoint(full) {
					font-size: rem(70px);
					line-height: rem(80px);
				}

				span {
					font-weight: 300;
					text-transform: none;
					display: block;
					font-size: rem(18px);
					line-height: rem(32px);

					@include breakpoint(small) {
						font-size: rem(22px);
						line-height: rem(37px);
					}

					@include breakpoint(large) {
						font-size: rem(28px);
						line-height: rem(42px);
						letter-spacing: rem(3px);
					}
				}
			}
		}
	}

}

// Basic full width Slideshow with Images as backround-image
.headerSwiper {
	padding: 0;

	.swiper-wrapper {
		.swiper-slide {
			> div {
				position: relative;
				display: block;
				height: rem(600px);

				@include breakpoint(large) {
					height: rem(800px);
				}

				@include breakpoint(full) {
					height: rem(900px);
				}

				&.slide-1 {
					background: url(/images/layout/tiny-slide-1.jpg) no-repeat center/cover;

					@include breakpoint(tiny) {
						background: url(/images/layout/slide-1.jpg) no-repeat center/cover;
					}		
				}
				&.slide-2 {
					background: url(/images/layout/tiny-slide-2.jpg) no-repeat center/cover;	

					@include breakpoint(tiny) {
						background: url(/images/layout/slide-2.jpg) no-repeat center/cover;
					}
				}
				&.slide-3 {
					background: url(/images/layout/tiny-slide-3.jpg) no-repeat center/cover;

					@include breakpoint(tiny) {
						background: url(/images/layout/slide-3.jpg) no-repeat center/cover;
					}		
				}

				.claimBox {
					position: absolute;
					bottom: 0;
					right: rem(70px);
					height: rem(180px);
					max-width: rem(300px);
					display: flex;
					align-items: center;
					justify-content: center;
					background: url('/images/layout/lines.jpg');
					box-shadow: -5px 5px 30px 0px rgba($dark,0.5);

					@include breakpoint(tiny) {
						right: rem(100px);
						max-width: rem(400px);
					}

					@include breakpoint(small) {
						max-width: rem(550px);
					}

					@include breakpoint(medium) {
						max-width: rem(660px);
						right: rem(150px);
					}

					p {
						font-weight: 800;
						padding: rem(25px) rem(35px);
						letter-spacing: rem(2px);

						@include breakpoint(tiny) {
							padding: rem(25px) rem(40px);
						}

						@include breakpoint(small) {
							font-size: rem(20px);
							line-height: rem(30px);
						}

						@include breakpoint(medium) {
							padding: rem(25px) rem(80px);
						}

						@include breakpoint(large) {
							font-size: rem(25px);
							line-height: rem(35px);
						}

						span {
							display: block;
							font-weight: 300;
							font-size: rem(16px);
							line-height: rem(26px);
							letter-spacing: 0;

							@include breakpoint(small) {
								font-size: rem(18px);
								line-height: rem(28px);
								letter-spacing: rem(2px);
							}

							@include breakpoint(large) {
								font-size: rem(20px);
								line-height: trm(30px);
							}
						}
					}
				}
			}
		}
	}

	.swiper-pagination {
		background: rgba(0,0,0,0.3);
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: rem(10px) 0;
		left: auto;
		height: rem(180px);

		.swiper-pagination-bullet {
			display: block;
			height: rem(15px);
			width: rem(15px);
			float: right;
			background: $light;
			opacity: 1;

			&.swiper-pagination-bullet-active {
				background: $primary;
				box-shadow: 0px 0px 20px 3px rgba($primary,0.5);
			}
		}
	}
}

body {
	&:not(.index) {
		.headerSwiper {
			display: none;
		}

		#header {
			@include responsiveImage('layout/slide-1.jpg', (default), true);

			&.lazyLoaded {
				background-size: cover;
			}

			.row {
				position: relative;

				.claim {
					blockquote, .btn {
						display: none;
					}
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
		color: $medium;

		strong {
			color: $light;
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	> .row {
		@extend .space;

		+ .row {
			body:not(.index) &:after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100vw;
				border-top: $baseBorder;
			}
		}
	}
}

.rowProduktbeschreibungen {
	@include breakpoint(medium) {
		display: flex;
	}

	.beschreibung {
		height: 100%;
		background: $dark;
		border-radius: rem(10px);
		box-shadow: 6px -2px 15px rgba(0,0,0, .35);
		padding: rem($baseGap);
		transition: 300ms all ease-in-out;

		@include breakpoint(small) {
			padding: rem(30px);
		}

		@include breakpoint(medium) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		@include breakpoint(large) {
			padding: rem(50px);
		}

		@include breakpoint(huge) {
			padding: rem(70px);
		}

		&:hover {
			box-shadow: 0px 0px 45px 5px rgba($primary,0.8);
		}

		.row {
			.col {
				&.colFlex {
					display: flex;
					flex-direction: column;

					@include breakpoint(small) {
						flex-direction: row;
					}

					@include breakpoint(medium) {
						flex-direction: column;
					}

					@include breakpoint(huge) {
						flex-direction: row;
					}
				}

				img {
					width: 100%;
					height: 100%;
					max-width: rem(250px);
					max-height: rem(300px);
					object-fit: contain;
					display: block;
					margin: 0 auto;
					padding-bottom: rem(36px);

					@include breakpoint(small) {
						margin-right: rem(30px);
					}

					@include breakpoint(medium) {
						margin-right: auto;
					}

					@include breakpoint(huge) {
						margin-right: rem(50px);
					}

					&.extra {
						@include breakpoint(small) {
							margin-right: auto;
						}

						@include breakpoint(huge) {
							margin-right: auto;
						}
					}
				}
			}
		}
	}
}

// TEASER
//////////////////////////////

// If you want to use these three mixins set them in a seperated mixins.scss

@mixin row {
	margin-left: auto;
	margin-right: auto;
	max-width: rem($rowMaxWidth);
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

@mixin col4 {
	width: 100%;
	max-width: calc(33.33333% - #{rem($baseGap*2)});
	flex-basis: calc(33.33333% - #{rem($baseGap*2)});
	margin: rem($baseGap);
	display: inline-block;
	transition: 300ms all;
	box-sizing: border-box;
}

@mixin col3 {
	width: 100%;
	max-width: calc(25% - #{rem($baseGap*2)});
	flex-basis: calc(25% - #{rem($baseGap*2)});
	margin: rem($baseGap);
	display: inline-block;
	transition: 300ms all;
	box-sizing: border-box;
}

// ------------------------------------------------------------

// Mobile Teaser Slider / Desktop Image+Text Teaser (No Slider)

.teaserImageSwiper {
	width: 100%;

	@include breakpoint(large) {
		overflow: visible!important;
	}

	.swiper-wrapper {
		@include breakpoint(large) {
			@include row; // requires mixin
			height: auto;
			overflow: visible;
			justify-content: center;
		}

		.swiper-slide {
			position: relative;
			height: auto; // auto = alle gleich hoch, 100% = Element Höhe
			display: flex;
			margin-bottom: rem(36px);

			.teaserInner {
				position: relative;
				text-align: center;
				z-index: 2;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				border-radius: rem(10px);
				background: $dark;
				width: 100%;

				@include breakpoint(large) {
					width: 100%;
				}

				.teaserImage {
					display: flex;
					justify-content: center;
					align-items: center;
					
					img {
						max-width: rem(244px);
						width: 100%;
						margin: rem(50px) 0 rem(25px);
						max-height: rem(260px);
						height: 100%;
						object-fit: contain;
					}
				}


				span {
					font-weight: 300;
					margin-bottom: rem(50px);
					transition: 300ms all ease-in-out;
					font-size: rem(16px);
					line-height: rem(26px);
					padding: 0 rem($baseGap);

					@include breakpoint(huge) {
						font-size: rem(18px);
						line-height: rem(28px);
					}

					strong {
						font-weight: 800;
					}
				}

				&:hover {
					box-shadow: 0px 0px 45px 5px rgba($primary,0.8);

					span {
						color: $primary;
					}
				}
			}

			&.large-4  {
				@include breakpoint(large) {
					@include col4;
				}
			}

			&.huge-3 {
				@include breakpoint(huge) {
					@include col3;
				}
			}

			&.full-3 {
				@include breakpoint(full) {
					@include col3;
				}
			}
		}
	}
}

.timeline {
	position: relative;
	display: block;
	padding-top: rem(36px);
	margin-bottom: rem(50px);

	@include breakpoint(medium) {
		padding-top: rem(70px);
		margin-bottom: rem(100px);
	}

	&:before {
		content: '';
		height: 100%;
		width: rem(4px);
		background: $light;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		display: block;
	}

	div {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;
		align-items: center;

		@include breakpoint(medium) {
			justify-content: space-between;
			flex-direction: row;
			align-items: flex-start;
		}

		&:before {
			content: '';
			background: $primary;
			border-radius: rem(100px);
			width: rem(20px);
			height: rem(20px);
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			box-shadow: 5px 5px 25px 5px rgba($primary,0.5);
			z-index: 2;
			transition: 300ms all ease-in-out;

			@include breakpoint(large) {
				width: rem(37px);
				height: rem(37px);
				box-shadow: 5px 5px 40px 5px rgba($primary,0.5);
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: rem(-20px);
			left: 50%;
			transform: translateX(-50%);
			background: $border;
			border-radius: rem(100px);
			width: rem(63px);
			height: rem(63px);
			z-index: 1;

			@include breakpoint(large) {
				width: rem(80px);
				height: rem(80px);
			}
		}

		&:last-child {
			p {
				margin-bottom: rem(30px);
			}
		}

		p {
			max-width: rem(520px);
			width: 100%;
			background: $border;
			text-align: center;
			z-index: 20;
			margin-bottom: rem(30px);
			margin-top: rem(70px);
			padding: rem(15px) 0;

			@include breakpoint(medium) {
				text-align: left;
				margin-bottom: rem(140px);
				margin-top: 0;
				background: transparent;
				padding: 0 rem(50px) 0 0;
			}

			@include breakpoint(giant) {
				padding: 0;
			}

			&:nth-child(2n) {
				@include breakpoint(medium) {
					padding: 0 0 0 rem(50px);
				}

				@include breakpoint(giant) {
					padding: 0;
				}
			}

			&:first-child {
				@include breakpoint(medium) {
					text-align: right;
				}
			}
			
			.h5 {
				margin-bottom: 0;
				text-align: center;

				@include breakpoint(medium) {
					text-align: left;
				}
			}

			&.text {
				position: relative;
				margin-bottom: rem(70px);
				margin-top: 0;

				&:before {
					@include breakpoint(giant) {
						content: '';
						position: absolute;
						height: rem(4px);
						width: rem(15px);
						background: $light; 
						top: rem(15px);
						left: rem(-30px);
					}

					@include breakpoint(huge) {
						width: rem(50px);
						left: rem(-100px)
					}

					@include breakpoint(full) {
						left: rem(-60px)
					}

					@include breakpoint(cut) {
						width: rem(80px);
						left: rem(-102px);
					}
				}
			}
		}

		&:nth-child(2n) {
			.text {
				&:before {
					@include breakpoint(giant) {
						right: rem(-30px);
						z-index: 10;
						left: auto;
					}

					@include breakpoint(huge) {
						right: rem(-100px);
					}

					@include breakpoint(full) {
						right: rem(-60px); 
					}

					@include breakpoint(cut) {
						right: rem(-102px); 
					}
				}

				.h5 {
					@include breakpoint(medium) {
						text-align: right;
					}
				}
			}
		}

		&:hover {
			&:before {
				background: $alert;
				box-shadow: 5px 5px 25px 5px rgba($alert,0.5);
				
				@include breakpoint(large) {
					box-shadow: 5px 5px 40px 5px rgba($alert,0.5);
				}
			}
		}
	}
}

.container {
	background: $border;
}


#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $border;

	.googleMaps {
		padding-bottom: rem(200px);

		@include breakpoint(tiny) {
			padding-bottom: rem(300px);
		}

		@include breakpoint(small) {
			padding-bottom: rem(400px);
		}
	}

	.row {
		.col {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			padding: rem(50px) rem(30px);

			@include breakpoint(small) {
				flex-flow: row wrap;
				justify-content: flex-start;
			}

			@include breakpoint(medium) {
				padding: rem(70px) rem(30px);
			}

			@include breakpoint(large) {
				justify-content: space-between;
			}

			@include breakpoint(huge) {
				padding: rem(100px) rem(30px);
			}

			@include breakpoint(cut) {
				padding: rem(140px) rem(30px);
			}

			@include breakpoint(mega) {
				padding: rem(140px) 0;
			}

			.line {
				height: rem(1px);
				width: rem(100px);
				background: $light;
				opacity: 0.2;
				position: relative;
				display: block;
				margin: rem(30px) 0;

				@include breakpoint(small) {
					display: none;
				}

				@include breakpoint(large) {
					display: inline-block;
					width: rem(1px);
					height: rem(45px);
					margin: 0 rem(10px);
				}

				@include breakpoint(huge) {
					height: rem(50px);
				}

				&.firstLine {
					@include breakpoint(large) {
						display: none;
					}

					@include breakpoint(huge) {
						display: inline-block;
					}
				}
			}

			.branding {
				display: block;
				width: 100%;

				@include breakpoint(small) {
					width: 50%;
					float: left;
				}

				@include breakpoint(large) {
					width: 100%;
					margin-bottom: rem(70px);
				}

				@include breakpoint(huge) {
					width: auto;
					margin-bottom: 0;
				}

				img {
					max-width: rem(171px);
					margin: 0 auto;
					display: block;

					@include breakpoint(small) {
						margin: 0;
					}

					@include breakpoint(large) {
						margin: 0 auto;
					}
				}
			}

			p {
				text-align: center;

				@include breakpoint(small) {
					width: 50%;
					text-align: left;
					margin: rem(15px) 0;
				}

				@include breakpoint(medium) {
					margin: rem(30px) 0;
				}

				@include breakpoint(large) {
					width: auto;
					font-size: rem(17px);
					line-height: rem(27px);
					margin: 0;
				}

				@include breakpoint(giant) {
					font-size: rem(18px);
					line-height: rem(28px);
				}

				strong {
					font-weight: 800;
					letter-spacing: rem(2px);
				}
			}
		}
	}
}

.ewcms_loopline {
	color: $dark;
}