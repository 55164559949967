// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Open Sans", 
    $fileName: "open-sans-v17-latin", 
    $weights: ("300", "400", "700", "800"), 
    $types: all
);